.swal2-container {
    z-index: 20000 !important;
  }

.swal2-styled.swal2-confirm {
  background-color: #07b53b !important;
}

.topIndex {
  z-index: 10000 !important;
}

.react-datepicker-popper {
  z-index: 10000 !important;
}

.lightBox {
  z-index: 2000 !important;
  /* position: fixed;
  height: 100vh;
  top: 0 ;
  bottom: unset; */
}

/* .lightbox-image-pager{
  transform: translate(0px,-50%) !important;
  top:50% !important;
} */